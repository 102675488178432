import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { SocialMediaIcon } from '../components/SocialMediaIcon';

const IndexPage = ({ data }) => {
	const page = data.markdownRemark.frontmatter;
	const html = data.markdownRemark.fields.intro_html;

	const blogPosts = data.allMarkdownRemark.nodes;

	return <Layout
		title={ page.title }
		description={ page.description ?? '' }
	>
		<ul className="pt-8 space-y-4 text-center grid justify-items-center">
			{ page.links.map((link, i) => <li
				key={ i }
				className="block clear-both w-72"
			>
				<a
					className="block border-2 rounded-md border-gray-500 p-2 px-4 -mx-4 md:mx-0 bg-white bg-opacity-5 hover:bg-opacity-10 hover:border-white"
					href={ link.url }
				>
					<SocialMediaIcon type={ link.type } />
					{ link.name }
				</a>
			</li>) }
		</ul>
	</Layout>;
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title,
				description,
				photo {
					childImageSharp {
						fixed(fit: COVER, height: 1024, width: 1024, cropFocus: ATTENTION, quality: 100) {
							...GatsbyImageSharpFixed
						}
					}
				},
				intro,
				links {
					name,
					type,
					url
				}
			},
			fields {
				intro_html
			}
		}

		allMarkdownRemark(
			filter: {frontmatter: {templateKey: {eq: "blog-post"}}},
			sort: {order: DESC, fields: frontmatter___date },
			limit: 5
		) {
			nodes {
				frontmatter {
					title,
					date(formatString:"yyyy-MM-DD")
				},
				fields {
					slug
				},
				excerpt
			}
		}
	}
`;
